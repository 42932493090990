import IconButton from "@mui/material/IconButton";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

export function ExpandIcons(props: {
  onClick: () => void;
  collapsed: boolean;
}) {
  return (
    <IconButton aria-label="toggle expanded content" onClick={props.onClick}>
      {props.collapsed ? (
        <ExpandMore color={"secondary"} />
      ) : (
        <ExpandLess color={"secondary"} />
      )}
    </IconButton>
  );
}
