export const rawColors = {
  black: "#222",
  white: "#FFF",
  monoBlack: "#000",
  monoWhite: "#FFFFFF",

  // Primary (Glacier)
  glacier100: "#E4F2FF",
  glacier200: "#C7E6FF",
  glacier300: "#A7CBE7",
  glacier400: "#89B1CB",
  glacier500: "#365D76",
  glacier600: "#233E50",
  glacier700: "#162B37",

  // Secondary (Teal)
  teal100: "#D7F6FF",
  teal200: "#B3ECFC",
  teal300: "#96CFDF",
  teal400: "#6199A8",
  teal500: "#2B6574",
  teal600: "#02424C",
  teal700: "#012A32",

  // Tertiary (Blue)
  blue100: "#E8F2FF",
  blue200: "#CFE5FF",
  blue300: "#50B2FE",
  blue400: "#067DC0",
  blue500: "#035687",
  blue600: "#003353",
  blue700: "#001D33",

  // Success (Green)
  green100: "#ECFFE4",
  green200: "#B7F2AE",
  green300: "#89D982",
  green400: "#6DBD6A",
  green500: "#39873A",
  green600: "#056118",
  green700: "#01450E",

  // Error (Red)
  red100: "#FFECEA",
  red200: "#FFB4AB",
  red300: "#FF8980",
  red400: "#FF534E",
  red500: "#DC3736",
  red600: "#A80616",
  red700: "#7D020C",

  // Neutral (Gray)
  gray100: "#F5F5F5",
  gray200: "#D1D1D1",
  gray300: "#ADADAD",
  gray400: "#888",
  gray500: "#646464",
  gray600: "#404040",
  gray700: "#1C1C1C",
};

/** Primitives */
export const primitives = {
  primary: {
    100: rawColors.glacier100,
    200: rawColors.glacier200,
    300: rawColors.glacier300,
    400: rawColors.glacier400,
    500: rawColors.glacier500,
    600: rawColors.glacier600,
    700: rawColors.glacier700,
  },
  secondary: {
    100: rawColors.teal100,
    200: rawColors.teal200,
    300: rawColors.teal300,
    400: rawColors.teal400,
    500: rawColors.teal500,
    600: rawColors.teal600,
    700: rawColors.teal700,
  },
  tertiary: {
    100: rawColors.blue100,
    200: rawColors.blue200,
    300: rawColors.blue300,
    400: rawColors.blue400,
    500: rawColors.blue500,
    600: rawColors.blue600,
    700: rawColors.blue700,
  },
  success: {
    100: rawColors.green100,
    200: rawColors.green200,
    300: rawColors.green300,
    400: rawColors.green400,
    500: rawColors.green500,
    600: rawColors.green600,
    700: rawColors.green700,
  },
  error: {
    100: rawColors.red100,
    200: rawColors.red200,
    300: rawColors.red300,
    400: rawColors.red400,
    500: rawColors.red500,
    600: rawColors.red600,
    700: rawColors.red700,
  },
  neutral: {
    100: rawColors.gray100,
    200: rawColors.gray200,
    300: rawColors.gray300,
    400: rawColors.gray400,
    500: rawColors.gray500,
    600: rawColors.gray600,
    700: rawColors.gray700,
  },
  mono: {
    white: rawColors.monoWhite,
    black: rawColors.monoBlack,
  },
  common: {
    white: rawColors.white,
    black: rawColors.black,
  },
};

/** Semantic Colors */
export const semantic = {
  surfaces: {
    light: {
      surface1: primitives.common.white,
      surface2: primitives.neutral[100],
    },
    dark: {
      surface1: primitives.common.black,
      surface2: primitives.neutral[700],
    },
  },
  content: {
    light: {
      content1: primitives.common.black,
      content2: primitives.neutral[200],
      content3: primitives.neutral[300],
      content4: primitives.neutral[400],
    },
    dark: {
      content1: primitives.common.white,
      content2: primitives.neutral[600],
      content3: primitives.neutral[400],
      content4: primitives.neutral[200],
    },
  },
  border: {
    light: primitives.neutral[200],
    dark: primitives.neutral[500],
  },
  brand: {
    light: {
      primary1: primitives.primary[600],
      primary2: primitives.primary[700],
      secondary1: primitives.secondary[500],
      secondary2: primitives.secondary[600],
      tertiary1: primitives.tertiary[300],
      tertiary2: primitives.tertiary[400],
    },
    dark: {
      primary1: primitives.primary[400],
      primary2: primitives.primary[500],
      secondary1: primitives.secondary[400],
      secondary2: primitives.secondary[500],
      tertiary1: primitives.tertiary[400],
      tertiary2: primitives.tertiary[500],
    },
  },
  system: {
    light: {
      error: primitives.error[500],
      success: primitives.success[400],
    },
    dark: {
      error: primitives.error[400],
      success: primitives.success[200],
    },
  },
};
