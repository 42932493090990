import { ReactNode, useEffect, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { Close, HelpOutline, OpenInFull } from "@mui/icons-material";
import {
  Box,
  Collapse,
  darken,
  lighten,
  Link,
  Modal,
  Stack,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { IconWithTooltip } from "../icon/IconWithTooltip";
import { useTheme } from "@mui/material/styles";
import { ExpandIcons } from "../icon/ExpandIcon";

interface CardHeaderWithActionsProps {
  title?: string;
  subheader?: string;
  infoTooltip?: string;
  collapsed?: boolean;
  handleToggleExpand?: () => void;
  handleOpenModal?: () => void;
  handleCloseModal?: () => void;
  isModal?: boolean;
}

export const getCardHeaderBackgroundColor = (theme: Theme) =>
  theme.palette.mode === "light"
    ? lighten(theme.palette.primary.light, 0.25)
    : darken(theme.palette.primary.light, 0.25);

const CardHeaderWithActions = ({
  title,
  subheader,
  infoTooltip,
  collapsed,
  handleToggleExpand,
  handleOpenModal,
  handleCloseModal,
  isModal,
}: CardHeaderWithActionsProps) => {
  return (
    <CardHeader
      sx={{
        backgroundColor: getCardHeaderBackgroundColor,
        padding: 1.25,
      }}
      titleTypographyProps={{
        variant: "h6",
        sx: {
          color: (theme: Theme) =>
            theme.palette.mode === "dark"
              ? theme.palette.getContrastText(theme.palette.primary.dark)
              : theme.palette.getContrastText(theme.palette.primary.main),
        },
        component: isModal ? "div" : Link,
        "aria-label": isModal ? undefined : "toggle expanded content",
        onClick: isModal ? undefined : handleToggleExpand,
      }}
      title={title}
      subheader={subheader}
      action={
        <Stack direction="row">
          {infoTooltip && (
            <IconButton>
              <IconWithTooltip
                IconComponent={HelpOutline}
                tooltipMessage={infoTooltip}
                iconColor={"secondary"}
              />
            </IconButton>
          )}
          {isModal ? (
            <IconButton aria-label="close modal" onClick={handleCloseModal}>
              <Close color={"secondary"} />
            </IconButton>
          ) : (
            <>
              <IconButton aria-label="open in modal" onClick={handleOpenModal}>
                <OpenInFull color={"secondary"} />
              </IconButton>
              <ExpandIcons onClick={handleToggleExpand} collapsed={collapsed} />
            </>
          )}
        </Stack>
      }
    />
  );
};

export function getCardBackgroundColor(theme: Theme) {
  return theme.palette.mode === "light"
    ? lighten(theme.palette.primary.main, 0.95)
    : darken(theme.palette.primary.main, 0.75);
}

export function CollapsableCardWithHeader({
  children,
  title,
  subheader,
  infoTooltip,
  collapsedOverride,
}: {
  children: ReactNode;
  title?: string;
  subheader?: string;
  infoTooltip?: string;
  collapsedOverride?: boolean;
}) {
  const [collapsed, setCollapsed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (collapsedOverride !== undefined) setCollapsed(collapsedOverride);
  }, [collapsedOverride]);

  const theme = useTheme();
  const handleToggleExpand = () => setCollapsed((oldExpanded) => !oldExpanded);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const cardElements = useMemo(
    () => (
      <Card
        sx={[
          {
            backgroundColor: getCardBackgroundColor,
          },
          modalOpen
            ? {
                height: "100%",
              }
            : {
                height: null,
              },
        ]}
      >
        <CardHeaderWithActions
          title={title}
          subheader={subheader}
          infoTooltip={infoTooltip}
          collapsed={collapsed}
          handleToggleExpand={handleToggleExpand}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          isModal={modalOpen}
        />
        {!modalOpen && <Collapse in={!collapsed}>{children}</Collapse>}
        {modalOpen && children}
      </Card>
    ),
    [modalOpen, title, subheader, infoTooltip, collapsed, children],
  );

  return (
    <>
      {cardElements}
      <Modal
        sx={{ zIndex: theme.zIndex.modal }}
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={[
            {
              position: "absolute" as const,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
            fullScreen
              ? {
                  width: "100vw",
                }
              : {
                  width: "90vw",
                },
            fullScreen
              ? {
                  height: "100vh",
                }
              : {
                  height: "90vh",
                },
          ]}
        >
          {cardElements}
        </Box>
      </Modal>
    </>
  );
}
