import { SvgIconComponent } from "@mui/icons-material";
import { SvgIconProps, Tooltip } from "@mui/material";

export function IconWithTooltip({
  IconComponent,
  tooltipMessage,
  iconColor,
}: {
  IconComponent: SvgIconComponent;
  tooltipMessage: string;
  iconColor?: SvgIconProps["color"];
}) {
  return (
    <Tooltip title={tooltipMessage}>
      <IconComponent color={iconColor} />
    </Tooltip>
  );
}
