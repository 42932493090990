import { Breakpoint, createTheme, ThemeOptions } from "@mui/material";
import { LinkProps } from "@mui/material/Link";
import { deepmerge } from "@mui/utils";

import { defaultDataGridLocaleText } from "@components/common/datagrid/defaultDataGridLocaleText";
import { RouterLink } from "@components/common/link/RouterLink";
import { PaletteOptions } from "@mui/material/styles";
import { createTypography } from "@theme/legacy_theme/create-typography";
import { primitives, rawColors, semantic } from "./colors"; // Import centralized colors
import getValueForBreakpoint from "./getValueForBreakpoint";

export type CreateAppThemeParams = {
  breakpoint: Breakpoint;
  mode: "dark" | "light";
  themeOptions?: ThemeOptions;
};

export function createAppTheme({
  mode,
  breakpoint,
  themeOptions = {},
}: CreateAppThemeParams) {
  const typography = createTypography();
  const spacing = getValueForBreakpoint({
    breakpoint,
    breakpointValues: {
      xs: 4,
      sm: 8,
    },
  });

  const isDarkMode = mode === "dark";

  const palette: PaletteOptions = {
    mode,
    common: {
      black: primitives.common.black,
      white: primitives.common.white,
    },
    text: {
      primary: isDarkMode
        ? semantic.content.dark.content1
        : semantic.content.light.content1,
    },
    primary: {
      main: isDarkMode
        ? semantic.brand.dark.primary1
        : semantic.brand.light.primary1,
    },
    secondary: {
      main: isDarkMode
        ? semantic.brand.dark.secondary1
        : semantic.brand.light.secondary1,
    },
    success: {
      main: isDarkMode
        ? semantic.system.dark.success
        : semantic.system.light.success,
    },
    error: {
      main: isDarkMode
        ? semantic.system.dark.error
        : semantic.system.light.error,
    },
    background: {
      default: isDarkMode
        ? semantic.surfaces.dark.surface2
        : semantic.surfaces.light.surface2,
      paper: isDarkMode
        ? semantic.surfaces.dark.surface1
        : semantic.surfaces.light.surface1,
    },
  };

  return createTheme(
    deepmerge(
      {
        spacing,
        palette,
        typography,
        zIndex: {
          // MUI Defaults but listed here so its clear, also see the useZIndexMap hook which contains specific component zIndex values
          mobileStepper: 1000,
          fab: 1050,
          speedDial: 1050,
          appBar: 1100,
          drawer: 1200,
          modal: 1300,
          snackbar: 1400,
          tooltip: 1500,
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                backgroundColor: "rgba(0, 0, 0, 0.82)",
                color: primitives.common.white,
                fontSize: "1rem",
              },
            },
          },

          MuiTabs: {
            styleOverrides: {
              root: {
                backgroundColor: palette.background.paper,
                borderRadius: "8px 8px 0 0",
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                "&.Mui-selected": {
                  fontWeight: 600,
                },
                textTransform: "none",
                ...typography.body2,
              },
            },
          },
          MuiCssBaseline: {
            styleOverrides: {
              html: {
                backgroundColor: palette.background.default,
              },
            },
          },
          MuiDataGrid: {
            defaultProps: {
              autosizeOnMount: true,
              localeText: defaultDataGridLocaleText,
              pageSizeOptions: [5, 10, 25, 50, 100],
              pagination: true,
              autosizeOptions: {
                expand: true,
                includeHeaders: true,
                includeOutliers: true,
                outliersFactor: 1.5,
              },
            },
            styleOverrides: {
              root: {
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "rgba(239,241,243,0.20)",
                },
                "& .MuiDataGrid-cell": {
                  padding: "8px",
                  backgroundColor: palette.background.paper,
                },
                "& .MuiDataGrid-filler": {
                  backgroundColor: palette.background.default,
                },
                "& .MuiDataGrid-cell[data-field='Actions']": {
                  backgroundColor: palette.background.default,
                },
                "& .MuiDataGrid-cell[data-field='Delete']": {
                  backgroundColor: palette.background.default,
                },
              },
              columnHeader: {
                backgroundColor: palette.background.default,
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: isDarkMode
                  ? palette.common.black
                  : palette.common.white,
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                backgroundColor: isDarkMode
                  ? palette.common.black
                  : palette.common.white,
              },
            },
          },
          MuiListItemButton: {
            styleOverrides: {
              root: {
                "&.Mui-selected": {
                  backgroundColor: isDarkMode
                    ? rawColors.glacier600
                    : rawColors.teal100,
                },
              },
            },
          },

          MuiIconButton: {
            defaultProps: {
              color: "secondary",
              size: "small",
            },
          },
          MuiSvgIcon: {
            defaultProps: {
              sx: {
                fontSize: "1.5rem",
              },
            },
          },
          MuiTextField: {
            defaultProps: {
              size: "small",
              fullWidth: true,
            },
          },
          MuiLink: {
            defaultProps: {
              component: RouterLink,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: RouterLink,
            },
          },
        },
      },
      themeOptions,
    ),
  );
}
