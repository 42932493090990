declare global {
  interface Window {
    interpolate: (text: string, values: (string | number)[]) => string;
    gettext: (text: string) => string;
    react_app_context: {
      server_base_url: string;
      log_level: "info" | "warning" | "error" | "debug";
      team: {
        id: string;
        slug: string;
        name: string;
        team_members: {
          first_name: string;
          last_name: string;
          id: number;
          email: string;
        }[];
      };
      user: {
        avatar_url: string;
        locale: string;
        user_id: string;
        is_anonymous: boolean;
        has_usable_password: boolean;
        is_superuser: boolean;
        name: string;
      };
    };
  }
}

const { server_base_url, user, log_level, team } =
  window.react_app_context || {};

export const appContext = {
  team,
  user,
  supportEmail: "support@capturedknowledge.ai",
  environment: import.meta.env.MODE,
  isProduction: false,
  logLevel: log_level?.toLowerCase() || "info",
  serverBaseUrl: server_base_url,
  muixLicenseKey:
    "0358af0238a52f621c44298122ad8d59Tz0xMDk0MjUsRT0xNzczMjczNTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
};

export const currentTeamMember = appContext.team.team_members.find(
  (member) => member.id === parseInt(appContext.user.user_id),
);

appContext.user.name =
  `${currentTeamMember.first_name} ${currentTeamMember.last_name}`.trim();
