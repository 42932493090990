import { useTheme } from "@mui/material";
import { rawColors, semantic } from "@theme/colors";

export function useHeaderColors() {
  const theme = useTheme();
  const backgroundColor =
    theme.palette.mode === "light" ? rawColors.teal400 : rawColors.teal400;
  const color =
    theme.palette.mode === "dark"
      ? theme.palette.getContrastText(semantic.brand.dark.primary2)
      : theme.palette.getContrastText(semantic.brand.light.primary1);

  return { backgroundColor, color };
}
