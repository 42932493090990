import { useTheme } from "@mui/material";

export function useZIndexMap() {
  const { zIndex } = useTheme();
  return {
    ...zIndex,
    cardDialog: zIndex.modal,
    actionDrawer: zIndex.drawer,
    responsiveDrawer: zIndex.modal,
    ticketDialog: zIndex.modal,
  };
}
