import { TextField, TextFieldProps } from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { CopyInputAdornment } from "./CopyInputAdornment";

export type TextFieldWithControllerProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  showCopyEndAdornment?: boolean;
  defaultValue?: PathValue<T, Path<T>>;
} & Pick<
  TextFieldProps,
  | "inputRef"
  | "rows"
  | "multiline"
  | "helperText"
  | "type"
  | "required"
  | "label"
  | "fullWidth"
  | "placeholder"
  | "disabled"
>;

export function TextFieldWithController<T extends FieldValues>({
  control,
  fullWidth = true,
  helperText,
  label,
  name,
  required,
  type,
  placeholder,
  disabled,
  defaultValue,
  multiline,
  rows,
  showCopyEndAdornment,
  inputRef,
}: TextFieldWithControllerProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field, fieldState, formState }) => {
        const { name, ref, onBlur, onChange, value = "" } = field;

        return (
          <TextField
            {...{
              InputProps: {
                endAdornment: showCopyEndAdornment ? (
                  <CopyInputAdornment value={value} />
                ) : undefined,
              },
              error: Boolean(fieldState.error),
              inputRef,
              fullWidth,
              placeholder,
              multiline,
              rows,
              helperText: fieldState?.error?.message || helperText,
              label,
              name,
              disabled: formState.disabled || disabled,
              onBlur,
              onChange,
              ref,
              value,
              type,
            }}
          />
        );
      }}
    />
  );
}
