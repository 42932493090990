import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";
import { ellipsis } from "../../../nest-app/utils/ellipsis";
import { Grid } from "../layout/Grid";
import { useHeaderColors } from "@theme/useHeaderColors";
import { gettext } from "@services/i18n";

export type TicketActionNavigationProps = {
  onNextActionClick?: () => void;
  onPrevActionClick?: () => void;
};

export function DialogAppBar({
  children,
  onCloseClick,
  title,
  renderRight,
}: {
  children?: ReactNode;
  onCloseClick: () => void;
  title?: string;
  renderRight?: () => ReactNode;
}) {
  const theme = useTheme();
  const isMediumOrSmaller = theme.breakpoints.down("md");

  const headerColors = useHeaderColors();

  return (
    <AppBar
      sx={{
        position: "relative",
        backgroundColor: headerColors.backgroundColor,
      }}
    >
      <Toolbar sx={{ flex: 1 }}>
        <Typography color={headerColors.color}>{children}</Typography>
        <Grid container direction="row" alignItems="center" flex={1}>
          <Typography
            variant={isMediumOrSmaller ? "h6" : "h5"}
            ml={2}
            color={"inherit"}
            title={title}
          >
            {ellipsis(title, 50)}
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            flex={1}
            spacing={1}
            alignItems="center"
          >
            {renderRight?.()}
            <Grid>
              <Tooltip title={`${gettext("Close")} - Hotkey: Escape`}>
                <IconButton
                  color="secondary"
                  sx={{
                    color: headerColors.color,
                  }}
                  onClick={onCloseClick}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
